import React, { useEffect, useRef, useState } from "react";
import RegularImage from "../../../images/slides/slide-b.jpg";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  BlockBetween,
  Button,
  Icon,
} from "../../../components/Component";
import { Label, Input, Row, Col } from "reactstrap";
import { useForm, FormProvider } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormDatePicker from "../../form-components/DatePicker";
import FormCheckbox from "../../form-components/CheckboxComponent";
import { useFormContext } from "react-hook-form";
import misc from "../../../api/misc/miscService";
import { ErrorMessage } from "./ErrorMessage";
import { Spinner, Alert } from "reactstrap";
import mastersServices from "../../../api/masters/mastersService";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { orderData } from "../../pre-built/trans-list/TransData";
// Define the structure of your form data
interface FormData {
  comAddressLine1: string;
  comAddressLine2: string;
  comCountry: string;
  comState: string;
  comDistrict: string;
  comPincode: string;
  comCity: string;
  registeredAddressLine1?: string;
  registeredAddressLine2?: string;
  registeredCountry?: string;
  registeredState?: string;
  registeredDistrict?: string;
  registeredCity?: string;
  registeredPincode?: string;
}
const ContactInformation_Buyer = (props: any) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();

  const [sameAsCompany, setSameAsCompany] = useState(true);

  const fieldNames = [
    "comAddressLine1",
    "comAddressLine2",
    "comCountry",
    "comState",
    "comDistrict",
    "comCity",
    "comPincode",
  ] as const;

  const watchFieldsArray = watch(fieldNames);
  const watchFields: FormData = fieldNames.reduce((acc, fieldName, index) => {
    acc[fieldName] = watchFieldsArray[index];
    return acc;
  }, {} as FormData);

  const watchCountry = watch("comCountry");
  const watchState = watch("comState");

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(10);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDitrictOptions] = useState([]);

  // const previousFormDate = watch();

  const getCountries = async () => {
    try {
      const _countries = await misc.getCountries();
      if (_countries.status) {
        if (_countries.data.length > 0) {
          const transformedData = _countries.data.map((item: any) => ({
            value: item.countryId.toString(),
            label: item.countryName,
          }));
          setCountryOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getStates = async (countryId: any) => {
    try {
      const states = await misc.getStatesByCountry(countryId);
      if (states.status) {
        if (states.data.length > 0) {
          const transformedData = states.data.map((item: any) => ({
            value: item.stateId.toString(),
            label: item.stateName,
          }));
          console.log(transformedData, "transformedData");
          setStateOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const getDistricts = async (stateID: any) => {
    try {
      const districts = await misc.getDistrictsByState(stateID);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDitrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const onCountryChange = (e: any) => {
    getStates(e);
  };

  const onStateChange = (e: any) => {
    console.log("stateChange")
    getDistricts(e);
  };


  useEffect(() => {
    if (watchCountry) {
      getStates(watchCountry); // Trigger state fetch when country changes
    }
  }, [watchCountry]);

  useEffect(() => {
    if (watchState) {
      getDistricts(watchState); // Trigger district fetch when state changes
    }
  }, [watchState]);

  useEffect(() => {
    if (sameAsCompany) {
      setValue("registeredAddressLine1", watchFields["comAddressLine1"]);
      setValue("registeredAddressLine2", watchFields["comAddressLine2"]);
      setValue("registeredCountry", watchFields.comCountry);
      setValue("registeredState", watchFields.comState);
      setValue("registeredDistrict", watchFields.comDistrict);
      setValue("registeredCity", watchFields.comCity);
      setValue("registeredPincode", watchFields.comPincode);
    }
  }, [sameAsCompany, watchFields, setValue]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (showAlert) {
      setCountdown(10);
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            setShowAlert(false);
            return 10;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [showAlert]);

  const submitForm = async (data: any) => {
    setLoading(true);
    try {
      const validation = await misc.onboardValidations({
        phoneNumber: data.adminPhoneNumber,
        adminEmail: data.adminEmail,
      });
      const errorMessage: ErrorMessage = validation;
      if (errorMessage?.status) {
        const errorsArray: string[] = [];
        for (const key in errorMessage.errors) {
          if (errorMessage.errors[key].status) {
            errorsArray.push(errorMessage.errors[key].message);
          }
        }
        setErrorMessages(errorsArray);
        setShowAlert(true);
        setLoading(false);
      } else {
        const updatedData = {
          ...data,
        };

        setLoading(true);
        const input = updatedData;
        if (input.partyType === "Seller") {
          input.categories = input.categories;
          input.materials = input.materials;
        }
        const _FormData: any = new FormData();
        const filesData: any = [];
        if (input?.files) {
          const files = input.files;
          _FormData.append("files", files[0]);
          filesData.push({
            indexOfFileLocated: 0,
            module: "orgs",
            purpose: "cheque",
            level: "Header Level",
            uniqueId: "",
            action: "Create",
          });
        }
        if (input?.orgLogo != "") {
          const imageFile = base64ToFile(input?.orgLogo, "orgLogo.png");
          if (imageFile) {
            _FormData.append("files", imageFile);
            filesData.push({
              indexOfFileLocated: 1,
              module: "orgs",
              purpose: "org logo",
              level: "Header Level",
              uniqueId: "",
              action: "Create",
            });
          }
        }
        _FormData.append("filesData", JSON.stringify(filesData));
        const output = {
          name: input.orgName,
          category: input.orgType,
          type: input.partyType,
          cin: input.cin,
          incorporationDate: input?.incorporationDate
            ?.toISOString()
            ?.split("T")[0],
          pan: input.pan,
          gstin: input.gstin,
          categories: input.categories,
          materials: input.materials,
          contactName: input.contactName,
          // lastName: input.lastName,
          adminEmail: input.adminEmail,
          phoneNumber: input.adminPhoneNumber,
          websiteURL: input.website,
          companyAddress: {
            addressLine1: input.comAddressline1,
            addressLine2: input.comAddressLine2,
            country: input.comCountry,
            state: input.comState,
            district: input.comDistrict,
            city: input.comCity,
            pincode: input.comPincode,
          },
          registeredAddress: {
            addressLine1: input.registeredAddressLine2,
            addressLine2: input.registeredAddressLine2,
            country: input.registeredCountry,
            state: input.registeredState,
            district: input.registeredDistrict,
            city: input.registeredCity,
            pincode: input.registeredPincode,
          },
          bankaccountNumber: input.bankaccountNumber,
          accountHolderName: input.accountName,
          bankName: input.bankName,
          branchName: input.branchName,
          ifcCode: input.ifcCode,
        };
        _FormData.append("orgData", JSON.stringify(output));
        try {
          const submitForm = await mastersServices.partyMaster(_FormData);
          if (!submitForm) {
            setLoading(false);
            return;
          }
          const { statusCode, message, status } = submitForm;
          if (status) {
            Swal.fire(
              "Onboarded",
              `${output.name} has been created`,
              "success"
            );
            navigate(`/party-list`);
            setLoading(false);
          } else {
            console.log("Error in form submission", message, submitForm);
            let errorMessage = "An error occurred";
            let fullErrorMessage;

            if (typeof message == "string") {
              fullErrorMessage = `${errorMessage}\n${"-"}\n${message}`;
            } else {
              try {
                fullErrorMessage = `${errorMessage}\n${"-"}\n${submitForm?.message?.join(
                  " "
                )}`;
              } catch (error) {
                fullErrorMessage = `${errorMessage}`;
              }
            }
            Swal.fire({
              title: "Error!",
              text: `${fullErrorMessage}`,
              icon: "error",
              confirmButtonText: "Cancel",
            });
            setLoading(false);
          }
        } catch (error: any) {
          console.log(error);
          setLoading(false);
          Swal.fire(`Error: ${error.message}`, "error");
        }
        setErrorMessages([]);
        setShowAlert(false);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error.message);
    }
  };
  function base64ToFile(base64String: string, fileName: string) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }

  return (
    <>
      {showAlert && (
        <div className="mb-3 custom-alert">
          <Alert
            color="danger"
            className="alert-icon"
            toggle={() => setShowAlert(false)}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Icon name="alert-circle" style={{ marginRight: "10px" }} />
                <ul style={{ margin: 0, padding: 0, listStyleType: "none" }}>
                  {errorMessages.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
              <span style={{ marginLeft: "auto", paddingRight: "10px" }}>
                {countdown}s
              </span>
            </div>
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          <Col lg="4">
            <FormInput
              name="contactName"
              label="Name (Org Admin)"
              required={false}
              type="text"
              placeholder="Enter First Name"
            />
          </Col>
          {/* <Col lg='4'>
                        <FormInput
                            name="lastName"
                            label="Last Name (Admin)"
                            required={false}
                            type="text"
                            placeholder="Enter Last Name"
                        />
                    </Col> */}
        </Row>

        <Row className="gy-4 mt-1">
          <Col lg="4">
            <FormInput
              name="adminEmail"
              label="Admin Email"
              required={true}
              type="email"
              placeholder="Enter Email address"
              pattern={/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="adminPhoneNumber"
              label="Phone Number"
              required={true}
              type="number"
              placeholder="Enter Admin Phone Number"
              pattern={/^(?:(\+91)?[\-\s]?)?[6-9]\d{9}$/}
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="website"
              label="Company URL "
              required={false}
              type="text"
              placeholder="Enter Website URL"
              pattern={
                /^(https?:\/\/)?(www\.)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/
              }
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <h6>Company Address</h6>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg="4">
            <FormInput
              name="comAddressline1"
              label="Address Line 1"
              disabled={true}
              required={true}
              type="text"
              placeholder="Street Address Line 1"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="comAddressLine2"
              label="Address Line 2"
              disabled={true}
              // required={true}
              type="text"
              placeholder="Street Address Line 2"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="comCountry"
              label="Country"
              options={countryOptions}
              required={true}
              disabled={true}
              placeholder="-Select-"
              // onChange={(e) => {
              //   onCountryChange(e);
              // }}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="4">
            <FormSelect
              name="comState"
              label="State"
              options={stateOptions}
              disabled={true}
              required={true}
              placeholder="-Select-"
              // onChange={(e) => onStateChange(e)}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="comDistrict"
              label="District"
              options={districtOptions}
              required={true}
              disabled={true}
              placeholder="-Select-"
            />
          </Col>
          <Col lg="4">
            <FormInput
              name="comCity"
              label="City/Town"
              required={true}
              disabled={true}
              type="text"
              placeholder="Enter City/Town"
              minLength={3}
              maxLength={30}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg="4">
            <FormInput
              name="comPincode"
              disabled={true}
              label="Zip/Pincode"
              required={true}
              type="text"
              placeholder="Enter Zip or pin code"
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <h6>Registerd Address</h6>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <FormCheckbox
              size={"medium"}
              name="registeredAddressSameAsCompany"
              optionLabel="Registered address same as company address"
              defaultChecked={sameAsCompany}
              onChange={() => setSameAsCompany(!sameAsCompany)}
            />
          </Col>
        </Row>
        {/* Conditional Billing Address Fields */}
        {!sameAsCompany && (
          <>
            <Row className="mt-4">
              <Col lg="4">
                <FormInput
                  name="registeredAddressLine1"
                  label="Registered Address Line 1"
                  required={true}
                  type="text"
                  placeholder="Registered Street Address Line 1"
                />
              </Col>
              <Col lg="4">
                <FormInput
                  name="RegisteredAddressLine2"
                  label="Registered Address Line 2"
                  // required={true}
                  type="text"
                  placeholder="Registered Street Address Line 2"
                />
              </Col>
              <Col lg="4">
                <FormSelect
                  name="RegisteredCountry"
                  label="Registered Country"
                  options={[]}
                  required={true}
                  placeholder="-Select-"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg="4">
                <FormSelect
                  name="RegisteredState"
                  label="Registered State"
                  options={[]}
                  required={true}
                  placeholder="-Select-"
                />
              </Col>
              <Col lg="4">
                <FormSelect
                  name="RegisteredDistrict"
                  label="Registered District"
                  options={[]}
                  required={true}
                  placeholder="-Select-"
                />
              </Col>
              <Col lg="4">
                <FormInput
                  name="RegisteredCity"
                  label="Registered City/Town"
                  required={true}
                  type="text"
                  placeholder="Enter City/Town"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col lg="4">
                <FormInput
                  name="Registered Pincode"
                  label="Registered Zip/Pincode"
                  required={true}
                  type="text"
                  placeholder="Enter Billing Zip or pin code"
                />
              </Col>
            </Row>
          </>
        )}
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" disabled={loading}>
                {loading ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
                disabled={loading}
              >
                Go Back
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};
export default ContactInformation_Buyer;
